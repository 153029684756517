/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'src/app/variables.scss';

@include mat.all-component-typographies();
@include mat.core();

$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gw-palette: (
  100: #b3e5ff,
  700: $form-color,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$gw-chkout-primary: mat.define-palette($gw-palette, 700);
$gw-chkout-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$gw-chkout-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$gw-chkout-theme: mat.define-light-theme((
  color: (
    primary: $gw-chkout-primary,
    accent: $gw-chkout-accent,
    warn: $gw-chkout-warn,
  )
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gw-chkout-theme);
@include mat.form-field-density(-2);

@import 'include-media/dist/_include-media.scss';
@import 'src/app/shared_variables.scss';
@import "sass-rem";
@import "sass-svg-uri";

$main-btn-shadow: 0 2px 30px #0000004d !default;

.mat-select-trigger {
	display: table;
	
	.mat-select-arrow-wrapper {
		vertical-align: top;
	}
}


html {
	box-sizing: border-box;

	@include media('>tablet') {
		height: 100%;
	}

	--mdc-theme-error: #d50000;
	--mat-select-invalid-arrow-color: #d50000;
	--mdc-filled-text-field-error-caret-color: #d50000;
	--mdc-filled-text-field-error-label-text-color: #d50000;
	--mdc-filled-text-field-error-hover-label-text-color: #d50000;
	--mat-form-field-error-text-color: #d50000;
	--mdc-outlined-text-field-error-outline-color: #d50000;
	--mdc-outlined-text-field-error-hover-outline-color: #d50000;
	--mdc-outlined-text-field-error-focus-outline-color: #d50000;
}

*, *:before, *:after {
	box-sizing: inherit;
}

* {
  max-height: 1000000px;
}

body {
  margin: 0;
	color: $main-color;
	background: $base-background-color;
	background-attachment: fixed;
	font: 15px $main-font;
	min-width: 320px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include media('>widescreen') {
		min-height: 100%;
		//padding-bottom: 150px;
		position: relative;
	}
}

// #region Buttons
.mdc-button.mdc-button--unelevated {
	&.gw-chkout-main-btn {
		background-color: $main-btn-bg;
		color: $main-btn-color;
		border-radius: $main-btn-radius;
		font-weight: $main-btn-weight;
		text-transform: $main-btn-txt-transform;
		box-shadow: $main-btn-shadow;
		height: 60px;
		font-size: 20px;
		letter-spacing: 0.5px;
	
		.gw-chkout-heading & {
			width: 360px;

			@include media('<phone') {
				width: 100%;
			}
		}
	}

	&.gw-chkout-main-btn--small {
		height: 48px;
		font-size: 18px;
	}

	&.gw-chkout-main-btn--light {
		background-color: transparent;
		color: $main-btn-bg;
		text-decoration: underline;
	}

	&.gw-chkout-main-btn--full {
		width: 100%;
	}

	&.gw-chkout-main-btn--flat {
		box-shadow: none;
	}
	
	&.gw-chkout-main-btn--notransform {
		text-transform: none;
	}

	&.gw-chkout-small-btn {
		color: $main-btn-bg;
		letter-spacing: normal;
	}
}

.mdc-button.gw-chkout-main-btn {
	&.gw-chkout-btn--rounded {
		border-radius: 25px;
		width: 163px;
		font-size: 14px;
		height: 36px;
		box-shadow: none;
		letter-spacing: 0;

		&.gw-chkout-btn--disabled {
			cursor: default;
    			pointer-events: none;
			color: var(--mdc-filled-button-disabled-label-text-color);
			background-color: var(--mdc-filled-button-disabled-container-color);
		}

		span {
			font-weight: 500;
		}
	}

	&[mat-stroked-button] {
		border-color: var(--mdc-outlined-button-label-text-color) !important;
	}
}
// #endregion

// #region Form
.mat-mdc-text-field-wrapper {
	&:not(.mdc-text-field--outlined) {
		padding: 0 8px;
		max-height: 48px;
	
		.mat-mdc-floating-label { 
			display: inline; 
			font-size: 15px;
			font-weight: 700;
			color: #979797;
		}
	
		.mat-mdc-form-field-infix {
			display: flex;
			align-items: flex-end;
			padding-bottom: 4px;
	
			.mat-mdc-select-value-text,
			.mat-mdc-input-element {
				font-size: 15px;
				line-height: 20px;
				letter-spacing: normal;
			}
		}
	
		.mdc-line-ripple::before {
			border-bottom-width: 2px;
		}
	}

	&.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
		border-bottom-color: rgba(0, 0, 0, 0.42);
	}
}

.mat-mdc-form-field .mat-mdc-form-field-error-wrapper {
	padding-left: 8px;
	font-size: 11px;
	background: no-repeat center right 8px url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 15L9 0 .5 15h17zM10 13H8v-2h2v2zm0-3H8V6h2v4z' fill='%23D50000' fill-rule='evenodd'/%3E%3C/svg%3E")
}

.mat-form-field-invalid {
	.mat-mdc-form-field-subscript-wrapper {
		margin-top: 2px;
		margin-bottom: 6px;
	}

	.mat-mdc-text-field-wrapper .mat-mdc-floating-label{
		color: var(--mdc-theme-error);
	}

	&.gw-chkout-field--border .mat-mdc-text-field-wrapper{
		border-color: var(--mdc-theme-error);
	}
} 

.mat-mdc-form-field-subscript-wrapper {
	&:has(.mat-mdc-form-field-hint) {
		margin-bottom: 6px;
	}

	.mat-mdc-form-field-hint-wrapper {
		padding: 0;
		font-size: 11px;
		color: #0000008a;
		margin-bottom: 6px;
	}
}

.mat-mdc-select-panel {
	padding: 0;
}

.gw-chkout-field {
	flex: 1;

	.gw-chkout-locations-search & .mat-mdc-form-field-subscript-wrapper {
		display: none;
	}
}

.gw-chkout-field--full {
	width: 100%;
}

.gw-chkout-location.gw-chkout-field {
		.mat-mdc-form-field-subscript-wrapper {
			margin-bottom: 0;
			margin-top: 0;
			// line-height: 16px;
		}
	}

.gw-chkout-field--border {
	.mat-mdc-text-field-wrapper {
		border: 2px solid #979797;
		border-radius: 3px;

		.mat-mdc-form-field-infix {
			padding-bottom: 6px;
		}
	}

	.mdc-line-ripple {
		display: none;
	}
}

.gw-chkout-field__flag {
	margin-right: 10px;
}

.gw-chkout-form {
	display: flex;
	gap: 4%;

	@include media('<tablet') {
		flex-direction: column;
	}
}

.gw-chkout-radio__item {
	display: flex;
	width: 100%;
	min-height: 48px;
	padding: 2px 0;
	border: 2px solid transparent;

	&.mat-mdc-radio-checked {
		border: 2px solid $form-color;
		border-radius: 24px;

		.gw-chkout-passport-opt-breakdown {
			display: block;
		}
	}

	.mdc-form-field {
		width: 100%;
		height: 100%;
	}

	.mdc-label {
		flex: 1;
	}
}

.gw-chkout-radio__item--center {
	.mdc-form-field {
		align-items: center;
	}
}

.gw-checkout-radio--submitted.ng-invalid {
	.mdc-label{
		color: var(--mdc-theme-error);
	}

	.gw-chkout-radio__item.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
		border-color: var(--mdc-theme-error);
	}

	.gw-chkout__radio-message {
		display: block;
		color: var(--mdc-theme-error);
	}
}

.gw-chkout__radio-message {
	display: none;
	width: auto;
	height: 42px;
	margin-left: 0.75rem;
	padding-top: 14px;
	padding-left: 24px;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	color: #ccc;
	line-height: 16px;
	background: no-repeat center left url('assets/svg/error-alert.svg');
}

.gw-chkout-addons {
	@include mat.checkbox-density(-3);

	.mdc-list-item.mdc-list-item--with-leading-checkbox {
		height: auto;
		margin-bottom: 20px;

		&:hover::before,
		&:focus::before {
			background: transparent;
		}

		.mat-mdc-list-option-checkbox-before {
			margin-right: 8px;
			margin-left: 0;
			align-self: flex-start;
		}
	}
}

form {
	margin: 0;
	padding: 0;
	border-style: none;
}

select {
	-webkit-border-radius: 0;
}

// #endregion


// adaptive images
img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

// google map fix
.gm-style img {
  max-width: none
}

// MIXINS
@mixin animate($properties, $duration: .3s, $easing: ease-in-out) {
	$list:();
	@each $prop in $properties {
		$str: #{$prop} #{$duration} #{$easing};
		$list: join($list, #{$str}, comma);
	}
	transition: $list;
}

// FORMS



// HELPERS

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
	font-weight: bold;
	margin: 0 0 0.5em;
}

h1, .h1 {
	margin-bottom: 16px;
	font-size: 27px;
	letter-spacing: 0.4px;

	@include media('>=tablet') {
		margin-bottom: 11px;
		letter-spacing: 0px;
		font-size: 32px;
	}

	@include media('>=widescreen') {
		font-size: 38px;
	}
}

h2, .h2 {
	font-size: 18px;
	letter-spacing: 0;

	@include media('>=tablet') {
		font-size: 24px;
	}
}

h3, .h3 {
	margin-bottom: 13px;
	font-size: 18px;

	@include media('>=tablet') {
		margin-bottom: 25px;
		font-size: 24px;
	}

	@include media('>=widescreen') {
		margin-bottom: 20px;
	}
}

h4, .h4 {
	font-size: 21px;
}

p {
	margin: 0 0 1em;
}

a {
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

// LAYOUT

.gw-checkout-container {
	width: 100%;
	max-width: 100%;
	margin: auto;
	padding: 25px 15px 50px 15px;

	// @include media('>=phone') {
		//width: 480px;
	// }

	// @include media('>=tablet') {
	// 	padding-top: 50px;
	// 	width: 90%;
	// }

	@include media('>=desktop') {
		width: 1024px;
	}

	@include media('>=widescreen') {
		width: 1200px;
	}
}

.row {
	box-sizing: border-box;

	@include media('>=tablet') {
		display: flex;
		align-items: stretch;
		gap: 30px;
	}
}

.col-left {
	flex: 1
}

.col-right {
	@include media('>=tablet') {
		width: 35%;
	}
}

.gwc-button__spinner.mat-mdc-progress-spinner circle {
  stroke: #fff;
}

.gw-chkout-traveler__select--desktop.mat-mdc-form-field {
  @include media ('<tablet') {
    display: none;
  }
}

.gw-chkout-traveler__select--mobile.mat-mdc-form-field {
	display: none;

  @include media ('<tablet') {
		display: block
  }
}

//customization button

.gw-chkout-main {
	background-color: #fff;
	box-shadow: 0 2px 12px rgba(#000, 0.08);
}

div.gw-chkout-elevation,
section.gw-chkout-elevation,
aside.gw-chkout-elevation,
div.gw-chkout-disclaimer--photo.gw-chkout-elevation {
	border-radius: 20px;
	box-shadow: 0px 4px 6.8px 4px #0942B326;
}

mat-expansion-panel .mat-expansion-panel-body {
  padding: 0;
}

mat-dialog-container.mat-dialog-container {
  padding: 0;
}

h3.gw-chkout-label {
  color: $form-label-clr;
  font-weight: $form-label-weight;
}

.gw-chkout-terms__title.mat-dialog-title{
  margin: 10px 24px;
  font-weight: bold;
}

.gw-chkout-terms__content.mat-dialog-content {
  margin: 0;
}

.gw-chkout-terms__grid {
  margin-bottom: 20px;

  mat-grid-tile {
    background-color: #ECECEC;
  }
}

.gw-chkout-country__reqs .mat-expansion-indicator {
	margin-right: 12px;
}

.gw-checkout-login-dialog .mat-dialog-container{
	position: relative;
}

.gw-chkout-chkbox {
	@include mat.checkbox-density(-3);
  padding: 12px 10px;
  background-color: $agreement-bg;
  display: block;

  a {
    text-decoration: underline;
  }

	.mdc-label {
		letter-spacing: normal;
		font-size: 13px;
		position: relative;
	}
}

.gw-chkout-home__aarp {
	width: 150px; 
	margin-right: 30px;
	align-self: flex-start;
	
	@include media('<tablet') {
		width: 100px;
		margin-right: 18px;
	}
}

gw-chkout-home-slider .gw-chkout-card .gw-chkout-card__body {
	@include media('<tablet') {
		padding-top: 0;
	}
}

// #region Button
.gw-chkout-grey-btn.mat-mdc-button.mat-unthemed {
	height: 48px;
	padding: 0 16px;
	border-radius: 24px;
	background-color: $secondary-btn;
	color: #FFF;
	font-weight: 700;
	box-shadow: 0 2px 30px rgba(0, 0, 0, 0.3);
	box-sizing: initial;
	font-size: 15px;
	letter-spacing: normal;
	min-width: 48px;
}

.gw-checkout-button__load {
  margin: 0 auto;
	--mdc-circular-progress-active-indicator-color: #FFF;
}

.gw-checkout-button__load--grey {
	--mdc-circular-progress-active-indicator-color: #d4dadf;
}

.gw-checkout-button__load--brand {
	--mdc-circular-progress-active-indicator-color: $main-btn-bg;
}
// #endregion

// #region List
.mdc-list-item__primary-text {
	--mdc-list-list-item-label-text-tracking: normal;
}
// #endregion

.gw-chkout__avatar-menu > div {
  padding: 0;
}

.gwc-header-message {
	a {
		color: inherit;
	}
}

.gwc-applicant-info-joined {
	@include mat.form-field-density(-3);

	.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)  {
		border: none;
		border-radius: 0;
		max-height: unset;
	}

	gw-chkout-location-select {
		background-color: #FFF;
		padding-top: 18px;
		overflow: visible;
		flex: 1;
		position: relative;
		
		.mat-mdc-text-field-wrapper{
			border: none;
			
			.mat-mdc-form-field-infix {
				display: flex;
			}

			.mdc-notched-outline__notch,
			.mdc-notched-outline__leading,
			.mdc-notched-outline__trailing {
				border: none;
			}

			input.mat-mdc-input-element {
				font-weight: 100;
			}
		} 

		.mat-mdc-form-field-subscript-wrapper {
			position: absolute;
			bottom: -22px;
			text-shadow: 0px 0px 1px white;
		}

		&:first-of-type {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

		&:not(:first-of-type) {
			border-left: 1px solid lightgrey;
		}
	}

	.mdc-button.mdc-button--unelevated.gw-chkout-main-btn {
		padding: 0 32px;
		height: 62px;
		box-shadow: none;
		width: auto;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
}

.gw-chkout-aaa-agent__group {
	.mat-mdc-option:not(:hover) {
		--mat-option-focus-state-layer-color: transparent;
	}

  > span{
    background-color: whitesmoke;
		font-weight: bold;
		opacity: 0.7;
  }
}

.gwc-home__section .gw-chkout-contact  {
	mat-list-item {
		padding: 0;
		--mdc-list-list-item-one-line-container-height: 36px;

		a {
			font-size: 16px!important;
		}
	}
}

@font-face {
	font-family: 'Mission Gothic';
	src: url('assets/fonts/MissionGothic/MissionGothicRegular.otf');
	font-weight: 400;
}

@font-face {
	font-family: 'Mission Gothic';
	src: url('assets/fonts/MissionGothic/MissionGothicBold.otf');
	font-weight: 700;
}

@font-face {
	font-family: 'Mission Gothic';
	src: url('assets/fonts/MissionGothic/MissionGothicRegularItalic.otf');
	font-weight: 400;
	font-style: italic;
}

@include media('<tablet') {
	.gw-chkout__hidemobile {
		display: none;
	}
}


@include media('>=tablet') {
	.gw-chkout__hidedesktop {
		display: none;
	}
}
