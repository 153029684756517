$btn-clipboard: '<svg width="18px" height="22px" xmlns="http://www.w3.org/2000/svg"><path fill="#FFFFFF" d="M16,2h-4.18C11.4,0.84,10.3,0,9,0S6.6,0.84,6.18,2H2C0.9,2,0,2.9,0,4v16c0,1.1,0.9,2,2,2h14 c1.1,0,2-0.9,2-2V4C18,2.9,17.1,2,16,2L16,2z M9,2c0.55,0,1,0.45,1,1S9.55,4,9,4S8,3.55,8,3S8.45,2,9,2L9,2z M16,20H2V4h2v3h10V4 h2V20L16,20z"/></svg>';
$btn-right-arrow: '<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M8 0L6.6 1.4 12.2 7H0v2h12.2l-5.6 5.6L8 16l8-8z" fill="#ffffff" fill-rule="evenodd" opacity="1"/></svg>';
$btn-lock: '<svg width="16" height="21" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="a" d="M0 0h24v24H0z"/></defs><g transform="translate(-4 -2)" fill="none" fill-rule="evenodd"><mask id="b" fill="currentColor"><use xlink:href="#a"/></mask><path d="M12 18c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V7c0-2.76-2.24-5-5-5S7 4.24 7 7v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2zM8.9 7c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H8.9V7zM18 21H6V11h12v10z" fill="currentColor" mask="url(#b)"/></g></svg>';

$breakpoints: (
	'phone': 480px,
	'tablet': 768px,
	'desktop': 1024px,
	'widescreen': 1200px
);

$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

$secondary-btn: #92959C !default;
$header-banner-bg: #92959C !default;
$header-banner-clr: #ffffff !default;
$slider-arrows: #0942B3;
$agreement-clr: #535050;
$agreement-bg: #FAFAFA;
$hero-btn-radius: 30px;
